import { useEffect, useRef } from 'react'
import welcomeImage from '../../images/welcomeImage.png'
import './WelcomeSection.scss'

const WelcomeSection = () => {
  const welcomeImageRef = useRef()

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting)
          entry.target.classList.add("fadeIn")
      })
    }, {
      threshold: 0.5
    })
    observer.observe(welcomeImageRef.current)
  }, [])

  return (    
    <section className="welcomeSection">
      <div className="welcomeText">
        <div className="motto">
           Casual Games
        </div>
      </div> 
      <div className="imageSection">
        <img 
          src={welcomeImage} 
          className='welcomeImage'
          ref={welcomeImageRef} 
          alt='Welcome'
          width={576*1.1} height={360*1.1}/>
      </div>
    </section>
  )
}

export default WelcomeSection
